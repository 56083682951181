.cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 350px;
  background-color: #f5e2cb;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 0;
  position: relative;
}

.card-text {
  padding-top: 40px;
  text-align: center;
  background-color: #f5e2cb;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: #713d11;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.card img {
  max-width: 45%;
  max-height: 45%;
  object-fit: contain;
  margin: 65px auto 0;
}

.card h3 {
  margin-top: 10px;
  font-size: 30px;
  color: #713d11;
  letter-spacing: 4.5px;
}

@media (max-width: 1200px) {
  .card {
    width: 45%;
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .card {
    width: 80%;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .card {
    width: 90%;
    margin-bottom: 20px;
    height: 350px;
  }

  .card img {
    max-width: 100%;
    height: auto;
  }

  .card-text {
    padding-top: 0px;
    font-size: 16px;
  }

  .card h3 {
    font-size: 24px;
  }
}

h1,
h2,
h3 {
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: bold;
}

p {
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
}

@media (max-width: 480px) {
  .card {
    width: 95%;
    height: 300px;
  }

  .card h3 {
    font-size: 20px;
  }

  .card-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  h2.headingTwo {
    letter-spacing: -0.5px;
    display: block;
  }
}
/* For screens below 1024px */
@media (max-width: 1024px) {
  .image-wrapper {
    position: absolute;
    top: 35%;
    left: 60%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* Adjust the size of the image for smaller screens */
  .image-between {
    width: 80%; /* Adjust the width of the image for smaller screens */
    max-width: 400px; /* Optional: Limit the width */
    height: auto; /* Maintain aspect ratio */
  }
}

/* For screens between 550px and 750px */
@media (min-width: 550px) and (max-width: 750px) {
  .image-wrapper {
    top: 42%; /* Slightly adjusted position for this screen size */
    left: 60%;
    transform: translate(-50%, -50%); /* Keep the centering */
    z-index: 10; /* Ensure it stays layered above sections */
  }

  /* Adjust the image size for screens between 550px and 750px */
  .image-between {
    width: 75%; /* Adjust width for medium screens */
    max-width: 450px; /* Optional: limit the max width */
    height: auto; /* Maintain aspect ratio */
  }
}

/* For screens between 750px and 1024px */
@media (min-width: 751px) and (max-width: 1024px) {
  .image-wrapper {
    top: 34%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* Adjust image size for medium-large screens */
  .image-between {
    width: 60%; /* Adjust the width for medium-large screens */
    max-width: 450px; /* Optional: Limit max width */
    height: auto; /* Maintain aspect ratio */
  }
}
@media (min-width: 760) and (max-width: 1024px) {
  .image-wrapper {
    top: 15%; /* Adjust the image's vertical positioning */
    left: 60%; /* Keep it horizontally centered */
    transform: translate(-50%, -50%); /* Maintain proper centering */
    z-index: 10; /* Ensure proper layer stacking */
  }

  /* Adjust image size for medium-large screens */
  .image-between {
    width: 60%; /* Adjust the width for medium-large screens */
    max-width: 450px; /* Optional: Limit max width */
    height: auto; /* Maintain aspect ratio */
  }
}
@media (min-width: 820px) and (max-width: 1024px) {
  .image-wrapper {
    top: 21%; /* Adjust the image's vertical positioning */
    left: 10%; /* Keep it horizontally centered */
    transform: translate(-50%, -50%); /* Maintain proper centering */
    z-index: 10; /* Ensure proper layer stacking */
  }

  /* Adjust image size for medium-large screens */
  .image-between {
    width: 60%; /* Adjust the width for medium-large screens */
    max-width: 430px; /* Optional: Limit max width */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (width: 1023.11px) {
  .image-wrapper {
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* Adjust the image for exactly 1023px width */
  .image-between {
    width: 90%;
    max-width: 300px;
    height: auto;
  }
}

/* Adjust the image for 1024x1366 dimensions (iPad Pro) */

@media (device-width: 1024px) and (device-height: 1366px) {
  .image-wrapper {
    top: 9%;
    left: 39%;
    transform: translate(-50%, -50%);
    z-index: 10;
    bottom: 70%;
    width: 90%;
    height: 9px;
  }

  .image-between {
    width: 90%;
    max-width: 1000px;
    max-height: 70px;
    height: 180px;
    object-fit: cover;
  }
  .horaires {
    max-height: 750px;
    overflow: hidden;
  }
}

@media (device-width: 820px) and (device-height: 1180px) {
  .image-wrapper {
    top: 15%;  
    left: 50%;  
    transform: translateX(-50%);  
    z-index: 10;
    max-width: 1150px;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }

  .horaires {
    max-height: 750px;
    overflow: hidden;
  }
}

@media (device-width: 412px) and (device-height: 892px) {
  .image-wrapper {
    top: 5%;  
    left: 50%;  
    transform: translateX(-50%);  
    z-index: 10;
    max-width: 1150px;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }

  .horaires {
    max-height: 700px;
    overflow: hidden;
  }
}


@media (device-width: 1920px) and (device-height: 1080px) {
  .image-wrapper {
    top: 4%;  
    left: 24%;  
    transform: translateX(-50%);  
    z-index: 10;
    max-width: 1150px;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }

  .horaires {
    max-height: 700px;
    overflow: hidden;
  }
}

@media (device-width: 1080px) and (device-height: 2424px) {
  .image-wrapper {
    top: 13%;  
    left: 35%;  
    transform: translateX(-50%);  
    z-index: 10;
    max-width: 1150px;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }

  .horaires {
    max-height: 700px;
    overflow: hidden;
  }
}

@media (device-width: 1180px) and (device-height: 820px) {
  .image-wrapper {
    top: 12%;
    left: 28%;
    transform: translate(-50%, -50%);
    z-index: 10;
    bottom: 70%;
    max-width: 1150px;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }
  .horaires {
    max-height: 750px;
    overflow: hidden;
  }
}

@media (device-width: 800px) and (device-height: 1280px) {
  .image-wrapper {
    top: -90%;
    left: 33%;
    transform: translate(-50%, -50%);
    z-index: 10;
    bottom: 53%;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }
  .horaires {
    max-height: 650px;
    overflow: hidden;
  }
}

@media (device-width: 768px) and (device-height: 1024px) {
  .image-wrapper {
    top: -90%;
    left: 33%;
    transform: translate(-50%, 0);
    z-index: 10;
    bottom: 55%;
    position: absolute;
  }

  .image-between {
    width: 50%;
    max-width: 600px;
    height: auto;
  }
  .horaires {
    max-height: 650px;
    overflow: hidden;
  }
  .horaires-res {
    margin-top: 5%;
  }
}

@media (device-width: 1024px) and (device-height: 600px) {
  .image-wrapper {
    top: 10%;
    left: 40%;
    transform: translate(-50%, 0);
    z-index: 10;
    bottom: 10%;
    height: 10px;
    position: absolute;
    width: 90%;
  }

  .image-between {
    width: 90%;
    max-width: 100px;
    max-height: 50px;
    height: 20px;
  }
}



@media (device-width: 932px) and (device-height: 430px) {
  .image-wrapper {
    top: -90%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10; 
    bottom: 60%;
    position: absolute;
  }

  .image-between {
    width: 90%;
    max-width: 100px;
   
    height: auto;
  }
}


@media (device-width: 360px) and (device-height: 740px) {
  .image-wrapper {
    top: -20%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10; 
    bottom: 30%;
    position: absolute;
  }

  .image-between {
    width: 90%;
    max-width: 100px;
   
    height: auto;
  }
}


@media (device-width: 360px) and (device-height: 640px) {
  .image-wrapper {
    top: -20%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10; 
    bottom: 30%;
    position: absolute;
  }

  .image-between {
    width: 90%;
    max-width: 100px;
   
    height: auto;
  }
}

@media (device-width: 640px) and (device-height: 360px) {
 .MammaMia{
  top: -260px;
  left: 120px;
  font-size: 10px;
 }
}
/*******************************************************************************************************************/


@media (min-width: 551px) and (max-width: 819px) {
  .image-wrapper {
    top: 42%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 350px;
    height: auto;
  }
}

@media (min-width: 530px) and (max-width: 767px) {
  .image-wrapper {
    top: 38.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 325px;
    height: auto;
  }
}

@media (min-width: 640px) and (max-width: 680px) {
  .image-wrapper {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 325px;
    height: auto;
  }
}





@media (min-width: 768px) and (max-width: 819px) {
  .image-wrapper {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 335px;
    height: auto;
  }
}

@media (min-width: 820px) and (max-width: 1000px) {
  .image-wrapper {
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 285px;
    height: auto;
  }
}



@media (min-width: 1001px) and (max-width: 1023px) {
  .image-wrapper {
    top: 36%;
    left: 15%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 590px;
    height: auto;
  }
}

/* For screens below 550px */
@media (max-width: 550px) {
  .image-wrapper {
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* Adjust the image for smaller screens */
  .image-between {
    width: 90%;
    max-width: 350px;
    height: auto;
  }
}

/* For screens below 470px */
@media (max-width: 470px) {
  .image-wrapper {
    top: 44%; /* Adjust the image's vertical position further for extra small screens */
    left: 50%;
    transform: translate(-50%, -50%); /* Ensure it remains centered */
    z-index: 10; /* Keep it on top */
  }

  /* Adjust image size for extra-small screens */
  .image-between {
    width: 100%; /* Take up almost the full screen width */
    max-width: 300px; /* Set a maximum width */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 639px) {
  .responsive-div {
    width: 95%;
  }
}


@media (min-width: 220px) and (max-width: 248px) {
  .image-wrapper {
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 590px;
    height: auto;
  }
}


@media (min-width: 250px) and (max-width: 300px) {
  .image-wrapper {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 590px;
    height: auto;
  }
}

@media (min-width: 321px) and (max-width: 365.90px) {
  .image-wrapper {
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 590px;
    height: auto;
  }
}

@media (min-width: 300px) and (max-width: 320px) {
  .image-wrapper {
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .image-between {
    width: 90%;
    max-width: 590px;
    height: auto;
  }

}




/**************************************************Pizza reponsive end here*******************************************/
@media (max-width: 250px) {
  .headingFlexWrap {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem; /* Smaller font size */
    position: relative;
  }

  .headingFlexWraptwo {
    position: relative;
    top: -10px; /* Correct vertical adjustment */
  }

  .horaires {
    position: relative;
    top: -20px;
  }
}

@media (max-width: 350px) {
  .headingFlex {
    font-size: 2.25rem; 
    line-height: 1.5;
    font-weight: 900 bolder;
  }
}


@media (max-width: 366px) {
  .text-base {
    font-size: 14px; /* Adjust font size for better fittin */
  }

  .text-center span,
  .text-left span {
    display: inline-block; /* Ensure times stay on the same line */
    margin-left: 5px; /* Adjust spacing */
  }

  .flex.items-center.justify-center {
    flex-direction: column; /* Stack elements if necessary */
    align-items: center;
  }
  
}


@media (max-width: 234px) {
  .text-base {
    font-size: 12px; /* Reduce font size for better fitting */
  }

  .flex.items-center.justify-center {
    flex-wrap: wrap; /* Allow elements to wrap within the container */
    gap: 4px; /* Add spacing between wrapped elements */
  }

  .flex.items-center.justify-center span {
    display: block; /* Ensure the location text stays below the SVG icon */
    margin-left: 0; /* Reset margin to prevent horizontal overflow */
    text-align: center; /* Center the text for a cleaner look */
  }

  .flex.items-center.justify-center svg {
    margin-bottom: 4px; /* Add spacing below the icon */
  }

  .relative {
    padding: 5px; /* Reduce padding for smaller screens */
  }

}

@media (max-width: 366px) {
  .text-base,
  .text-lg {
    font-size: 12px; /* Adjust font size for smaller screens */
    line-height: 1.4; /* Adjust line height for readability */
  }

  .text-2xl,
  .text-3xl,
  .text-4xl {
    font-size: 18px; /* Scale down heading sizes */
    line-height: 1.2; /* Adjust line height for headings */
  }

  .text-center {
    text-align: center; /* Center-align text for smaller screens */
  }

 

  

  .mt-4 {
    margin-top: 10px; /* Adjust margin for smaller screens */
  }
}

.menu-button:hover {
  top: -10px;
}