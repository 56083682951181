.footer {
  background-color: #EBE1D5;
  color: #713d11;
  padding: 50px 100px 20px 100px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-logo img {
  height: 120px;
  max-width: 100%;
  background: transparent;
}

.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.social-icons a {
  display: inline-block;
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons svg {
  width: 80px;
  height: 80px;
  fill: #713d11; /* Icon color */
  background: transparent;
}

#LinkedIn {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  margin-top: -12px;
}

.social-icons a:hover svg {
  transform: scale(1.1);
  fill: #5a3010; /* Darken color on hover */
}

.footer p {
  margin-top: 15px;
  text-align: center;
  color: #713d11;
}

h1,
h2,
h3 {
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: bold;
}

p {
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .footer {
    padding: 40px 60px 20px 60px;
  }

  .social-icons svg {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 20px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .footer-logo img {
    height: 100px;
  }

  .social-icons {
    margin-top: 15px;
  }

  .social-icons svg {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer-logo img {
    height: 80px;
  }

  .social-icons svg {
    height: 50px;
    width: 50px;
  }

  .social-icons {
    gap: 15px;
  }
}
