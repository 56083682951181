@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
body {

  font-family: 'Open Sans', 'Helvetica Neue', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body::-webkit-scrollbar {
  display: none;
}

/* For other browsers */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}