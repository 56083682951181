@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
