body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App {
  margin: 0;
  padding: 0;
}
